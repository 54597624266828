import React, { useEffect } from 'react'
import { TOKEN_KEY } from "utils/constants";

function Public() {



    useEffect(() => {
        const removeToken = () => {
            localStorage.removeItem(TOKEN_KEY);
        }

        const removeUserInfo = () => {
            localStorage.removeItem('React.LC.KY.AP');
            localStorage.removeItem('React.LC.KY.MS');
            localStorage.removeItem('module');
        }

        return () => {
            removeUserInfo();
            removeToken();
        }
    }, [])


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div
                style={{
                    position: 'absolute',
                    top: 100
                }}
            >
                <h1 style={{ textAlign: 'center', marginBottom: 1, fontWeight: 'bold' }}>INICIA SESIÓN DESDE SAM!</h1>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', marginBottom: 2
                }}>
                    <a href={`https://sam.educem.mx/`} style={{
                        font: '16px Arial',
                        padding: '9px 10px 9px 10px',
                        backgroundColor: '#293881',
                        color: '#ffffff',
                        borderRadius: 3,
                        textDecoration: 'none'
                    }}>REGRESAR A SAM!</a>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img alt='loading' src={require('../../static/2696469.jpg')} style={{ height: 280 }} />
                </div>
            </div>
        </div>
    )
}
export default Public;