import axios from "axios";
import { ADD_IMAGE, DELETE_IMAGE_BRAND, GET_IMAGES, UPDATE_IMAGE_BRAND, UPLOAD_IMAGE_BRAND } from "../utils/endpoints";

export const getImages = () => {
    return axios.get(GET_IMAGES());
}

export const updateImageInfo = (form) => {
    return axios.put(UPDATE_IMAGE_BRAND(), form, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const uploadImage = (form) => {
    return axios.put(UPLOAD_IMAGE_BRAND(), form, {
        headers: {
            'Content-Type': 'multi-part/form-data'
        }
    });
}

export const addImage = (form) => {
    return axios.post(ADD_IMAGE(), form, {
        headers: {
            'Content-Type': 'multi-part/form-data'
        }
    });
}

export const deleteImage = (id) => {
    return axios.delete(DELETE_IMAGE_BRAND(), id)
}