import {
    Layout,
    Card,
    Button,
    Image,
    Row,
    Col,
    Divider,
    Space,
    Badge,
    Select,
    Spin,
    Alert,
    Modal,
    Input,
    Popover,
} from "antd";
import { message } from "antd";
import { useAuthState } from "../context/authContext.tsx";
import React, { useEffect, useRef, useState } from "react";
import {
    addImage,
    getImages,
    updateImageInfo,
    uploadImage,
    deleteImage,
} from "../services";
import {
    CheckCircleOutlined,
    AuditOutlined,
    PlusOutlined,
    DeleteOutlined,
} from "@ant-design/icons";

const { Header, Content } = Layout;

const cardStyle = {
    width: "80%",
    boxShadow: "4px 4px 10px gray",
    alignSelf: "center",
    margin: 10,
};

const header = {
    fontSize: "20px",
    color: "white",
    width: "100vw",
    position: "fixed",
    zIndex: 99,
};

export default function Home() {

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [images, setimages] = useState([]);
    const [loadingImages, setloadingImages] = useState(false);
    const [error, seterror] = useState(false);
    const [imageFile, setimageFile] = useState();
    const [open, setopen] = useState(false);
    const [imageInfo, setimageInfo] = useState({
        imagestatus: true,
        imagestyle: 0,
        imagebrand: "",
    });
    const [idImagen, setidImagen] = useState(0);
    const [loadingSave, setloadingSave] = useState(false)

    const enumEstilos = {
        1: "Tiled",
        2: "Centered",
        3: "Stretched",
        4: "Fill",
        5: "Fit",
        6: "Span",
        7: "Stretch",
        8: "Tile",
        9: "Center",
    };

    const slcEstilos = [
        {
            value: 1,
            label: "Tiled",
        },
        {
            value: 2,
            label: "Centered",
        },
        {
            value: 3,
            label: "Stretched",
        },
        {
            value: 4,
            label: "Fill",
        },
        {
            value: 5,
            label: "Fit",
        },
        {
            value: 6,
            label: "Span",
        },
        {
            value: 7,
            label: "Stretch",
        },
        {
            value: 8,
            label: "Tile",
        },
        {
            value: 9,
            label: "Center",
        },
    ];

    const photoRef = useRef();
    const usr = useAuthState();

    const handleClick = () => {
        photoRef.current.click();
    };

    const handleChange = async (file, brand) => {
        console.log(brand)
        try {
            setLoading(true);

            if (file.target.files[0].size < 10e6) {
                setimageFile(file.target.files[0]);
                setImageUrl(URL.createObjectURL(file.target.files[0]));
                message.success("¡Foto seleccionada con éxito!");
                setLoading(false);
                setidImagen(brand);
            } else {
                message.info("La imagen no puede pesar más de 10MB");
                setLoading(false);
            }
        } catch (error) {
            message.error(error);
        }
    };

    const handleCancel = () => {
        setopen(false);
    };

    const handleUpdateEstatus = (item, value) => {
        let index = images.findIndex((a) => a.id === item.id);
        if (index !== -1) images[index].imagestatus = value;
    };

    const handleUpdateStyle = (item, value) => {
        let index = images.findIndex((a) => a.id === item.id);
        if (index !== -1) images[index].imagestyle = value;
    };

    const obtenerImagenes = () => {
        getImages().then((images) => {
            setloadingImages(true);
            if (images.status === 200) {
                setimages(images.data);
                setloadingImages(false);
            } else {
                seterror(true);
                setloadingImages(false);
            }
        });
    };

    const guardarCambios = async (id) => {
        setloadingSave(true)
        let aux = images.filter((i) => i.id === id);

        if (imageFile !== undefined) {
            //Aquí es cuando sí se cambia la foto
            let objImagen = {
                id: aux[0].id,
                imageurl: aux[0].imageurl,
                imagestatus: aux[0].imagestatus,
                imagestyle: aux[0].imagestyle,
                imagebrand: aux[0].imagebrand,
            };

            let form = new FormData();
            form.append("imageInfo", JSON.stringify(objImagen));
            form.append("file", imageFile);

            await uploadImage(form)
                .then(() => {
                    message.success("¡Datos actualizados exitosamente!");
                    obtenerImagenes();
                    setloadingSave(false)
                })
                .catch((err) => {
                    message.error(err);
                    setloadingSave(false)
                });
        } else {
            //Aquí únicamente se cambian los datos de la imagen
            let objImagen = {
                id: aux[0].id,
                imageurl: aux[0].imageurl,
                imagestatus: aux[0].imagestatus,
                imagestyle: aux[0].imagestyle,
                imagebrand: aux[0].imagebrand,
                imagedate: aux[0].imagedate,
            };

            await updateImageInfo(JSON.stringify(objImagen))
                .then(() => {
                    message.success("¡Datos actualizados exitosamente!");
                    obtenerImagenes();
                    setloadingSave(false)
                })
                .catch((err) => {
                    message.error(err);
                    setloadingSave(false)
                });
        }
    };

    const agregarImagen = async () => {
        if (imageInfo.imagebrand !== "" && imageFile !== undefined) {
            let form = new FormData();
            console.log(imageInfo);
            form.append("imageInfo", JSON.stringify(imageInfo));
            form.append("file", imageFile);

            await addImage(form)
                .then(() => {
                    setopen(false);
                    message.success("Pale tapiz creado exitosamente!");
                    obtenerImagenes();
                })
                .catch((err) => {
                    message.error(err);
                });
        } else {
            message.info("¡Por favor llenar todos los datos!");
        }
    };

    const borrarImagen = async (id) => {
        try {
            await deleteImage(id);
            message.success("¡Wallpaper eliminado con éxito!");
            obtenerImagenes();
        } catch (error) {
            message.error(error);
        }
    };

    useEffect(() => {
        obtenerImagenes();
    }, []);
    return (
        <Layout>
            <Header style={header}>
                Configuración de Wallpaper de planteles{" "}
                <Button
                    style={{ position: "absolute", right: 50, top: 15 }}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setopen(true)}
                >
                    {" "}
                    Agregar wallpaper
                </Button>
            </Header>
            <Content
                style={{
                    marginTop: "5%",
                    height: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                }}
            >
                {error && (
                    <Alert
                        message="¡Error!"
                        description="Ocurrió un error al conseguir los datos. Por favor inténtelo de nuevo."
                        type="error"
                        showIcon
                    />
                )}
                {loadingImages ? (
                    <Spin tip="Cargando..." size="large" />
                ) : (
                    images.map((i) => (
                        <Row style={{ width: "100%", justifyContent: "center" }}>
                            <Card
                                key={i.imagebrand}
                                bordered
                                title={"Marca: " + i.imagebrand}
                                style={cardStyle}
                                headStyle={{ fontSize: 20, backgroundColor: "lightgray" }}
                            >
                                <Row style={{ width: "100%" }}>
                                    <Col span={12} style={{ textAlign: "center" }}>
                                        <Image maxWidth={400} src={i.imageurl}></Image>
                                        <Divider></Divider>

                                        <Space direction="horizontal">
                                            <h3>Elegir foto para fondo de pantalla:</h3>

                                            <Button
                                                type="primary"
                                                shape="round"
                                                onClick={handleClick}
                                                icon={<PlusOutlined />}
                                                loading={loading}
                                            >
                                                {imageFile !== undefined
                                                    ? "Foto subida"
                                                    : "Escoger foto"}
                                            </Button>
                                            <input
                                                type="file"
                                                accept=".jpg, .png"
                                                ref={photoRef}
                                                onChange={(e) => handleChange(e, i.imagebrand)}
                                                style={{ display: "none" }}
                                            />
                                        </Space>
                                    </Col>

                                    <Col
                                        span={12}
                                        style={{ textAlign: "center", justifyContent: "center" }}
                                    >
                                        <Space direction="vertical">
                                            <h2>Estátus:</h2>
                                            {i.imagestatus ? (
                                                <Badge status="success" text="Activo" />
                                            ) : (
                                                <Badge status="error" text="Inactivo" />
                                            )}

                                            <b>Cambiar estátus del fondo:</b>
                                            <Select
                                                defaultValue={
                                                    i.imagestatus === true ? "Activo" : "Inactivo"
                                                }
                                                onChange={(e) => handleUpdateEstatus(i, e)}
                                                style={{ width: 200 }}
                                                options={[
                                                    {
                                                        value: true,
                                                        label: "Activo",
                                                    },
                                                    {
                                                        value: false,
                                                        label: "Inactivo",
                                                    },
                                                ]}
                                            ></Select>
                                        </Space>

                                        <Divider></Divider>
                                        <Space direction="vertical">
                                            <h2>Estilo de la imagen:</h2>
                                            <Select
                                                defaultValue={enumEstilos[i.imagestyle]}
                                                style={{ width: 200 }}
                                                name="estilo"
                                                onChange={(e) => handleUpdateStyle(i, e)}
                                                options={slcEstilos}
                                            />

                                            {imageUrl && idImagen === i.imageBrand ? (
                                                <Image style={{ maxWidth: 300 }} src={imageUrl} />
                                            ) : (
                                                <></>
                                            )}
                                        </Space>
                                    </Col>
                                </Row>

                                <Divider></Divider>
                                <Button
                                    style={{ backgroundColor: "green", border: "none" }}
                                    type="primary"
                                    icon={<CheckCircleOutlined />}
                                    shape="round"
                                    loading={loadingSave}
                                    onClick={() => guardarCambios(i.id)}
                                >
                                    Guardar cambios
                                </Button>

                                <Popover
                                    trigger="click"
                                    title="¿Estás segur@ de eliminar el wallpaper?"
                                    content={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-evenly",
                                            }}
                                        >
                                            <Button
                                                style={{ backgroundColor: "red", border: "none" }}
                                                type="primary"
                                                icon={<DeleteOutlined />}
                                                onClick={() => borrarImagen(i.id)}
                                            >
                                                Eliminar
                                            </Button>
                                            <Button type="primary">Cancelar</Button>
                                        </div>
                                    }
                                >
                                    <Button
                                        style={{ backgroundColor: "red", border: "none" }}
                                        type="primary"
                                        icon={<DeleteOutlined />}
                                        shape="round"
                                    >
                                        Eliminar
                                    </Button>
                                </Popover>
                            </Card>
                        </Row>
                    ))
                )}

                <Modal
                    title="Agregar un nuevo wallpaper a una marca"
                    open={open}
                    onOk={agregarImagen}
                    onCancel={handleCancel}
                    width={1000}
                >
                    <Row style={{ width: "100%" }}>
                        <Col span={12} style={{ textAlign: "center" }}>
                            <Image src={imageUrl}></Image>

                            <Divider></Divider>

                            <Space direction="vertical">
                                <b>Elegir foto para fondo de pantalla:</b>

                                <Button
                                    type="primary"
                                    shape="round"
                                    onClick={handleClick}
                                    icon={<PlusOutlined />}
                                    loading={loading}
                                >
                                    {imageFile !== undefined ? "Foto subida" : "Escoger foto"}
                                </Button>
                                <input
                                    type="file"
                                    accept=".jpg, .png"
                                    ref={photoRef}
                                    onChange={handleChange}
                                    style={{ display: "none" }}
                                />
                            </Space>
                        </Col>

                        <Col
                            span={12}
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                padding: 20,
                            }}
                        >
                            <b>Nombre de la marca:</b>
                            <Input
                                size="medium"
                                placeholder="Nombre de la marca"
                                prefix={<AuditOutlined />}
                                onChange={(e) =>
                                    setimageInfo({ ...imageInfo, imagebrand: e.target.value })
                                }
                            />

                            <Divider></Divider>
                            <Space direction="vertical">
                                <b>Estilo de la imagen:</b>
                                <Select
                                    style={{ width: 200 }}
                                    name="estilo"
                                    onChange={(e) =>
                                        setimageInfo({ ...imageInfo, imagestyle: e })
                                    }
                                    options={slcEstilos}
                                />
                            </Space>
                        </Col>
                    </Row>
                </Modal>
            </Content>
        </Layout>
    )
}
