import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuthState } from './context/authContext';
import { GET_USER_LOGIN_ENDPOINT, GET_USER_INFO_BY_EMAIL_ENDPOINT } from './utils/endpoints/ap/ApEndpoints';
import { SC_KEY, TOKEN_KEY } from './utils/constants';
import MasterLogin from './pages/MasterLogin';
import GeneralLoad from './components/general/GeneralLoad';
import Home from 'pages/Home';
import ErrorGeneral from 'pages/ErrorGeneral';
const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
}

const RenderAppFetchData = () => {

  const [dataAP, getDataAP] = useState();
  const [dataMS, getDataMS] = useState();
  const [isError, setIsError] = useState(false);
  const user = useAuthState();
  var CryptoJS = require("crypto-js");
  var access = false;

  const returnUser = () => { window.location.href = `https://sam.educem.mx`; }

  useEffect(() => {
    if (!dataAP) {
      fetch(GET_USER_LOGIN_ENDPOINT, { headers: new Headers({ 'Authorization': `Bearer ${user.token}` }) }).then((res) => res.json()).then((data) => getDataAP(data)).catch(err => setIsError(true));
    }
    if (!dataMS) {
      fetch(GET_USER_INFO_BY_EMAIL_ENDPOINT(user.email), { headers: new Headers({ 'Authorization': `Bearer ${user.token}` }) }).then((res) => res.json()).then((data) => getDataMS(data)).catch(err => setIsError(true));
    }

  }, [dataAP, dataMS]);

  if (isError) {

  } else {
    if (!dataAP || !dataMS) {
    } else {
      if(dataMS.useractive){
        dataAP.scopes.map((scope) => {
          console.log(scope)
          if (scope.scopeid === 27) {
            access = true;
          }
        });
      }


      if (access) {
        if (dataMS.status !== 500) {
          if (localStorage.getItem('React.LC.KY.AP') === null) {
            var dataAp_encrypt = CryptoJS.AES.encrypt(JSON.stringify(dataAP), SC_KEY).toString();
            localStorage.setItem('React.LC.KY.AP', dataAp_encrypt)
          }

          if (localStorage.getItem('React.LC.KY.MS') === null) {
            var dataMS_encrypt = CryptoJS.AES.encrypt(JSON.stringify(dataMS), SC_KEY).toString();
            localStorage.setItem('React.LC.KY.MS', dataMS_encrypt);
          }
        } else {
          removeToken();
          //returnUser();
        }

      } else {
        removeToken();
        //returnUser();
      }
    }
  }

  return (
    <>
      {(isError) ?
        <ErrorGeneral></ErrorGeneral> : (!dataAP || !dataMS) ?
          <GeneralLoad></GeneralLoad>
          : <BrowserRouter>
            <Routes>
              <Route path="/masterlogin/" element={<MasterLogin />}></Route>
              <Route path="/" element={<Navigate path="/" to="/papel-tapiz" />}> </Route>
              <Route path="/papel-tapiz" element={<Home />}></Route>
            </Routes>
          </BrowserRouter>
      }
    </>

  )
}

export default RenderAppFetchData;

