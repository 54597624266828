import { Layout, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useAuthState } from "../context/authContext.tsx";

export default function ErrorGeneral() {
  const user = useAuthState();

  if (!user)
    return (
      <Layout>
        <Content>
          <Image src="url(../static/2696469.jpg)" />
        </Content>
      </Layout>
    );
}
