export default function GeneralLoad() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 200,
                }}
            >
                <img alt='loading' src={require('../../static/img/loading.gif')} style={{ height: 180 }} />
                <h6 style={{ marginBottom: 2, textAlign: 'center' }}>Estamos obteniendo tu información</h6>
            </div>
        </div>
    )
}