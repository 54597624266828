import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RenderAppFetchData from './RenderAppFetchData';
import MasterLogin from './pages/MasterLogin';
import { useAuthState } from './context/authContext';
import Public from './pages/Public';

function App() {
  const usr = useAuthState();

  return (
    (!usr.token) ?
      <BrowserRouter>
        <Routes>
          <Route path="/masterlogin/" element={<MasterLogin />}></Route>
          <Route path="/" element={<Navigate path="/" to="/public" />}> </Route>
          <Route path="/public" element={<Public />}> </Route>
        </Routes>
      </BrowserRouter>
      : <RenderAppFetchData></RenderAppFetchData>
  );
}

export default App;
